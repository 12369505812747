import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { delay, Observable, of } from 'rxjs';
import { StoreWalletService } from '@libs/app.services/store/store-wallet.service';
import { AuthService } from '@libs/app.services/landing/auth.service';
@Injectable({
  providedIn: 'root',
})
export class PendingDepositsResolver implements Resolve<boolean> {
  constructor(
    private storeWalletService: StoreWalletService,
    private authService: AuthService,
  ) {}

  resolve(): Observable<boolean> {
    setTimeout(
      () => {
        this.storeWalletService.loadPendingDeposits();
      },
      this.authService.logged() ? 0 : 2000,
    );
    return of(true).pipe(delay(1000));
  }
}
