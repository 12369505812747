import { Component, OnInit, Renderer2, Inject, NgZone, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { TelegramService } from '@libs/app.services/account/telegram.service';
import { first, switchMap, tap } from 'rxjs';
import { UserService } from '@libs/app.services/account/user.service';

@Component({
  selector: 'app-provider-telegram',

  imports: [CommonModule],
  templateUrl: './provider-telegram-ask.component.html',
  styleUrl: './provider-telegram-ask.component.scss',
})
export class ProviderTelegramAskComponent implements OnInit {
  onconnected = output<boolean>();
  apiUrl = 'https://api.lbets.io/telegram/auth';
  connect$;
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private telegramService: TelegramService,
    private ngZone: NgZone,
    private userService: UserService,
  ) {}

  ngOnInit() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://telegram.org/js/telegram-widget.js?7';
    script.async = true;
    script.setAttribute('data-telegram-login', this.telegramService.botName);
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    window['onTelegramAuth'] = data => this.ngZone.run(() => this.onAuth(data));

    const wrapper = this.document.querySelector('.wrapper-telegram');
    if (wrapper) {
      this.renderer.appendChild(wrapper, script);
    }
  }
  onAuth(data: any) {
    this.telegramService
      .providerAdd(data)
      .pipe(
        tap(() => this.onconnected.emit(true)),
        switchMap(() => this.userService.fetchUser()),
        first(),
      )
      .subscribe();
  }
}
