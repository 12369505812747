import { BONUS_STATE, BONUS_TYPE, CURRENCY, IBonus } from '@libs/interfaces';

export const Bonuses: IBonus[] = [
  {
    desc: 'Bônus recharge',
    image_url: 'https://lotodobicho.com/gallery/bonus-recarga-50.webp',
    denom: BONUS_TYPE.RECHARGE,
    currency: CURRENCY.BRL,
    state: BONUS_STATE.ACTIVE,
  },
  {
    desc: 'Bônus de fidelidade',
    image_url: 'https://lotodobicho.com/gallery/bonus-fidelidade.webp',
    denom: BONUS_TYPE.FIDELIDADE,
    currency: CURRENCY.BRL,
    state: BONUS_STATE.ACTIVE,
  },
  {
    desc: 'Bônus cashback',
    image_url: 'https://lotodobicho.com/gallery/bonus-cash-back.webp',
    denom: BONUS_TYPE.CASHBACK,
    currency: CURRENCY.BRL,
    amount: 50,
    state: BONUS_STATE.ACTIVE,
  },
  {
    image_url: 'https://lotodobicho.com/gallery/bonus-test-drive-5.webp',
    denom: BONUS_TYPE.TESTDRIVE_5,
    desc: 'Bônus test drive R$5',
    currency: CURRENCY.BRL,
    amount: 5,
    state: BONUS_STATE.CLOSED,
  },
  {
    image_url: 'https://lotodobicho.com/gallery/bonus-test-drive-10.webp',
    denom: BONUS_TYPE.TESTDRIVE_10,
    desc: 'Bônus test drive R$10',
    currency: CURRENCY.BRL,
    amount: 10,
    state: BONUS_STATE.CLOSED,
  },
  {
    image_url: 'https://lotodobicho.com/gallery/bonus-test-drive-20.webp',
    denom: BONUS_TYPE.TESTDRIVE_20,
    desc: 'Bônus test drive R$20',
    currency: CURRENCY.BRL,
    amount: 20,
    state: BONUS_STATE.CLOSED,
  },
  {
    desc: 'Bônus de boas-vindas',
    image_url: 'https://lotodobicho.com/gallery/bonus-boas-vindas-100.webp',
    denom: BONUS_TYPE.BEMVINDO,
    currency: CURRENCY.BRL,
    state: BONUS_STATE.CLOSED,
  },
  {
    desc: 'Bônus de boas-vindas 100%',
    image_url: 'https://lotodobicho.com/gallery/bonus-boas-vindas-100.webp',
    denom: BONUS_TYPE.BEMVINDO_100,
    currency: CURRENCY.BRL,
    state: BONUS_STATE.CLOSED,
  },
  {
    desc: 'Bônus de boas-vindas 50%',
    image_url: 'https://lotodobicho.com/gallery/bonus-boas-vindas-50.webp',
    denom: BONUS_TYPE.BEMVINDO_50,
    currency: CURRENCY.BRL,
    state: BONUS_STATE.CLOSED,
  },
];
