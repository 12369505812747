import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '@libs/app.services/account/user.service';
import { UserStoreService } from '@libs/app.services/store/user-store.service';
@Injectable({
  providedIn: 'root',
})
export class UserDocsResolver implements Resolve<boolean> {
  constructor(
    private userService: UserService,
    private userStoreService: UserStoreService,
    private router: Router,
  ) {}

  resolve(): Observable<boolean> {
    const canActivateByState = this.userStoreService.userDocsCompleted();
    const canActivateByTelegramConnected = this.userStoreService.isTelegramProviderAdded();
    if (!canActivateByState) return this.toastState();
    if (!canActivateByTelegramConnected) return this.toastTelegram();
    return of(true);
  }
  toastState() {
    this.userService.msgWithdrawDocsCanntActivate();
    this.router.navigateByUrl('/account/me');
    return of(false);
  }
  toastTelegram() {
    this.userService.msgTelegramConnectedCanntActivate();
    this.router.navigateByUrl('/account/me');
    return of(false);
  }
}
