import { Route } from '@angular/router';
import { CasinoResolver } from '@libs/resolvers/resolvers-landing/casino.resolver';
import { DrawResolver } from '@libs/resolvers/resolvers-landing/draw.resolver';
import { PendingDepositsResolver } from '@libs/resolvers/resolvers-landing/pending-deposits.resolver';
import { UserDataResolver } from '@libs/resolvers/resolvers-landing/user-data.resolver';
import { UserDocsResolver } from '@libs/resolvers/resolvers-landing/user-docs.resolver';
import { AuthService } from '@libs/app.services/landing/auth.service';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () => import('./components/landing/landing.component').then(m => m.LandingComponent),
    data: { title: 'Jogo do Bicho Online - A Melhor Plataforma de Apostas | Lotodobicho' },
    children: [
      {
        path: 'app',
        loadComponent: () => import('@libs/stand-alone-components/bicho/bicho.component').then(m => m.BichoComponent),
        data: { title: 'Jogo do Bicho Online - A Melhor Plataforma de Apostas | Lotodobicho' },
        children: [
          {
            path: 'draws',
            loadComponent: () =>
              import('@libs/stand-alone-components/bicho/commons/draws/draws-locator/draws-locator').then(
                m => m.DrawsLocatorComponent,
              ),
            data: { title: 'Sorteios do Jogo do bicho | Lotodobicho' },
          },
          {
            path: 'draw/:draw_id',
            resolve: {
              draw: DrawResolver,
            },
            loadComponent: () => import('@libs/stand-alone-components/bicho/draw/draw').then(m => m.DrawComponent),
            data: { title: 'Sorteio | Lotodobicho' },
            canActivate: [AuthService],
          },
        ],
      },
      {
        path: 'casino',
        loadComponent: () => import('./components/casino/casino.component').then(m => m.CasinoComponent),
        data: { title: 'Casino lobby | Lotodobicho' },
        resolve: {
          casino: CasinoResolver,
        },
        children: [
          {
            path: 'stage',
            loadComponent: () =>
              import('./components/casino/stage/game-stage.component').then(m => m.GameStageComponent),
            canActivate: [AuthService],
          },
        ],
      },
      {
        path: 'sports',
        loadComponent: () => import('./components/sports/lobby-component').then(m => m.LobbySportComponent),
        data: { title: 'Sports lobby | Lotodobicho' },
      },
      {
        path: 'wallet',
        loadComponent: () =>
          import('@libs/stand-alone-components/wallet/landing/wallet-menu.component').then(m => m.WalletMenuComponent),
        data: { title: 'Wallet deposits && withdraws' },
        canActivate: [AuthService],
        children: [
          {
            path: 'deposits',
            loadComponent: () =>
              import('@libs/stand-alone-components/wallet/deposits/deposits.component').then(m => m.DepositsComponent),
            resolve: {
              pendingDeposits: PendingDepositsResolver,
            },
            children: [
              {
                path: 'fiat',
                loadComponent: () =>
                  import('@libs/stand-alone-components/wallet/deposits/fiat/deposit-fiat.component').then(
                    m => m.DepositFiatComponent,
                  ),
                resolve: {
                  data: UserDataResolver,
                },
                children: [
                  {
                    path: 'pix',
                    loadComponent: () =>
                      import('@libs/stand-alone-components/wallet/deposits/fiat/pix/pix').then(
                        m => m.DepositFiatPixComponent,
                      ),
                    data: { title: 'Deposito pix - lotodobicho' },
                  },
                  // {
                  //   path: 'boleto',
                  //   loadComponent: () =>
                  //     import('@libs/stand-alone-components/wallet/deposits/fiat/boleto/boleto').then(
                  //       m => m.BoletoComponent,
                  //     ),
                  //   data: { title: 'Deposito boleto - lotodobicho' },
                  // },
                  // {
                  //   path: 'cc',
                  //   loadComponent: () =>
                  //     import('@libs/stand-alone-components/wallet/deposits/fiat/cc/cc-ask').then(m => m.CcComponent),
                  //   data: { title: 'Deposito cartão  - lotodobicho' },
                  // },
                ],
              },
              // {
              //   path: 'crypto',
              //   loadComponent: () =>
              //     import('./components/wallet/deposits/crypto/deposits/deposits-area.component').then(
              //       m => m.DepositsCryptoComponent,
              //     ),
              // },
            ],
          },
          {
            path: 'withdraws',
            resolve: {
              data: UserDocsResolver,
            },
            loadComponent: () =>
              import('@libs/stand-alone-components/wallet/withdraws/withdraws.component').then(
                m => m.WithdrawsComponent,
              ),
          },
        ],
      },
      {
        path: 'account',
        loadComponent: () => import('@libs/stand-alone-components/account/account').then(m => m.AccountComponent),
        data: { title: 'Account' },
        canActivate: [AuthService],
        children: [
          {
            path: 'me',
            loadComponent: () =>
              import('@libs/stand-alone-components/account/profile/my-data').then(m => m.MyDataComponent),
          },
          {
            path: 'movements',
            loadComponent: () =>
              import('@libs/stand-alone-components/account/transactions/wallet-transactions.component').then(
                m => m.WalletTransactionsComponent,
              ),
          },
          {
            path: 'game-history',
            loadComponent: () =>
              import('@libs/stand-alone-components/account/historical/historical').then(m => m.HistoricalComponent),
          },
        ],
      },
      {
        path: 'rewards',
        loadComponent: () =>
          import('@libs/stand-alone-components/rewards/rewards.component').then(m => m.RewardsComponent),
        children: [
          {
            path: 'indication',
            loadComponent: () =>
              import('@libs/stand-alone-components/rewards/rewards-indication/rewards-indication.component').then(
                m => m.RewardsIndicationComponent,
              ),
          },
          {
            path: 'bonus',
            loadComponent: () =>
              import('@libs/stand-alone-components/rewards/rewards-bonus-list/rewards-bonus-list.component').then(
                m => m.RewardsBonusListComponent,
              ),
          },
          {
            path: 'points',
            loadComponent: () =>
              import('@libs/stand-alone-components/rewards/rewards-points/rewards-points.component').then(
                m => m.RewardsPointsComponent,
              ),
          },
        ],
      },
    ],
  },
  {
    path: 'sign',
    loadChildren: () => import('@libs/stand-alone-components/sign/app.module').then(m => m.AppModule),
    data: { title: 'Sign' },
  },

  {
    path: '**',
    redirectTo: '/',
  },
];
